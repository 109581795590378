

















































































































import { SfOverlay, SfButton, SfBadge } from "@storefront-ui/vue";
import SfHeaderChange from "./Header/SfHeaderChange.vue";
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import { useUiHelpers, useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";
import LogoVue from "~/components/HeaderLogoVue.vue";
import { isEmpty } from "lodash-es";

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeaderChange,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    LogoVue,

    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBarHeader: () =>
      import("~/components/Header/SearchBar/SearchBarHeader.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
    CategoryProductGridSearch: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductGridSearch.vue"
      ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
    } = useUiState();
    const { setTermForUrl, getCatLink, getFacetsFromURL } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const searchTerm = ref("");
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);
    const categoryTreeMenu = ref<CategoryTree[]>([]);
    const {
      categories: categoryList,
      load: categoriesListLoad,
    } = useCategory();
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push("/customer/my-profile");
      } else {
        toggleLoginModal();
      }
    };
    const menu = (category) => {
      var n = [];
      category.forEach((item) => {
        if (Boolean(item?.children?.length)) {
          n.push({
            label: item.name,
            children: menu(item.children),
            link: `${item.url_path}${item.url_suffix || ""}`,
          });
        } else if (Number(item.product_count) > 0) {
          n.push({
            label: item.name,
            children: [],
            link: `${item.url_path}${item.url_suffix || ""}`,
          });
        }
      });
      return n;
    };
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });
      categoryTree.value = categoryList.value?.[0]?.children;
      categoryTreeMenu.value = menu(categoryTree.value);

      // .filter(
      //   (category) => category.include_in_menu
      // );
    });
    onMounted(async () => {
      searchTerm.value = getFacetsFromURL().term;
      // let localCategory = null;
      // if (localStorage.getItem("categoryTree") !== "undefined") {
      //   localCategory = JSON.parse(localStorage.getItem("categoryTree"));
      // } else {
      //   localCategory = [];
      // }
      // console.log("localCategory", localCategory);
      // console.log("localCategory", typeof localCategory);
      // if (localCategory.length > 0) {
      //   categoryTree.value = JSON.parse(localCategory);
      //   console.log("categoryTree.value0", categoryTree.value);
      // } else {
      //   console.log("ccategoryList", categoryList);
      //   console.log("categoryTree.value1", categoryTree.value);
      //   const categoryTreeParsed = JSON.stringify(categoryTree.value);
      //   localStorage.setItem("categoryTree", categoryTreeParsed);
      // }
      // const localMenu = JSON.parse(localStorage.getItem("menu"));
      // console.log("localMenu", localMenu);
      // if (localMenu !== undefined && localMenu != null && localMenu != []) {
      //   try {
      //     categoryTreeMenu.value = JSON.parse(localMenu);
      //   } catch (e) {
      //     localStorage.removeItem("menu");
      //   }
      // } else {
      //   categoryTreeMenu.value = menu(categoryTree.value);
      //   const menuParsed = JSON.stringify(categoryTreeMenu.value);
      //   localStorage.setItem("menu", menuParsed);
      // }
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });
    const redirectUrlPath = (params) => {
      if (!isEmpty(searchTerm.value))
        router.push("/catalogsearch/result/?term=" + searchTerm.value);
    };
    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      categoryTreeMenu,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      searchTerm,
      redirectUrlPath,
      getFacetsFromURL,
    };
  },
  watch:{
    $route (){
      this.searchTerm = this.getFacetsFromURL().term;
    }
  },
});
